<template>
  <div id="categoryPage">
    <!--顶部导航-->
    <div class="top">
      <TabbarTopNav :newMessage="newMessage"></TabbarTopNav>
    </div>
    <!--顶部导航-end-->

    <!--一级分类-->
    <van-tabs
      v-model="tabActive"
      :ellipsis="false"
      background="#232324"
      color="#36ABF5"
      title-inactive-color="rgba(255, 255, 255, 0.6)"
      title-active-color="#fff"
      @click="tabClick"
    >
      <van-tab
        :title="item.name"
        v-for="item in tabs"
        :key="item.id"
        :name="item.id"
      ></van-tab>
    </van-tabs>
    <!--一级分类-end-->
    <div class="container">
      <div class="section">
        <div class="section-title">
          <!--二级分类-->
           <swiper
            v-if="classify1.length"
            ref="swiper"
            class="list"
            :options="swiperOptions"
            >
              <swiper-slide
                class="list-item"
                v-for="item in classify1"
                :key="item.id"
              >
                <div
                  class="item"
                  @click="onClassify('twoCategoryId', item.id)"
                  :class="{active: params.twoCategoryId === item.id}"
                >{{item.name}}</div>
              </swiper-slide>
            </swiper>
            <swiper
              ref="swiper"
              class="list"
              :options="swiperOptions"
            >
              <swiper-slide
                class="list-item"
                v-for="(item, index) in classify2"
                :key="index"
              >
                <div
                  class="item"
                  :class="{active: params.sortType === item.type}"
                  @click="onClassify('sortType', item.type)"
                >{{item.name}}</div>
              </swiper-slide>
            </swiper>
          <swiper
            ref="swiper"
            class="list"
            :options="swiperOptions"
          >
            <swiper-slide
              class="list-item"
              v-for="(item, index) in classify3"
              :key="index"
            >
              <div
                class="item"
                :class="{[`active ${item.activeBg || ''}`]: params.type === item.type}"
                @click="onClassify('type', item.type)"
              >{{item.name}}</div>
            </swiper-slide>
          </swiper>
          <!--二级分类-end-->
        </div>
        <div class="section-content">
          <Empty
            v-if="detail.totalCount===0"
            tip="関連コンテンツはありません"
          ></Empty>
          <van-list
            v-else
            :finished="detail.finished"
            v-model="detail.loading"
            loading-text="ローディング..."
            @load="getDetail"
            offset="20"
          >
            <!--列表-->
            <van-row class="list" type="flex">
              <van-col
                v-for="(item, index) in detail.list"
                :key="index"
                :span="section1.span"
              >
                <router-link
                  :to="{name: 'Detail', query: {id: item.id}}"
                  class="list-item"
                >
                  <ListItem1 :detail="item" @showFun="detailShowFun(item)"></ListItem1>
                </router-link>
              </van-col>
            </van-row>
            <!--列表-end-->
          </van-list>
        </div>
      </div>
    </div>

    <!-- 分享与稍后播放弹层 -->
    <PopupShow
      :detail="actions"
      @later="later"
      :showFun="showFun"
      @upDate="upDate"
      @share="share"
    >
    </PopupShow>
  </div>
</template>

<script>
import { Empty, TabbarTopNav, ListItem1, PopupShow } from '@/components';
import { Tab, Tabs } from 'vant';
import { mapGetters } from 'vuex';
export default {
  name: 'Category',
  components: {
    'van-tab': Tab,
    'van-tabs': Tabs,
    TabbarTopNav,
    ListItem1,
    Empty,
    PopupShow
  },
  data () {
    return {
      tabs: [],
      newMessage: false,
      classify2: [{
        name: '最新作',
        type: 1
      }, {
        name: '人気一番',
        type: 2
      }],
      classify3: [{
        name: '無料',
        type: 1
      }, {
        name: '４K動画',
        type: 3
      }, {
        name: 'HD',
        type: 2,
        activeBg: 'bg-1'
      }],
      detail: {
        finished: false,
        loading: true,
        pageNo: 1,
        pageSize: 10,
        totalCount: '',
        list: []
      },
      params: {
        categoryId: '', // 视频类目编号
        twoCategoryId: 0, // 一级分类id
        sortType: '', // 排序类型(1.最新上传,2.最多播放)
        type: '' // 视频类型(1.免费,2.VIP,3.4K)
      },
      tabActive: 0,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 0
      },
      section1: {
        span: 24
      },
      showFun: false, // 稍后观看与分享的弹层
      actions: {},
      newArr: []
    };
  },
  computed: {
    ...mapGetters(['htmlWidth']),
    classify1 () {
      const { tabs, tabActive } = this;
      return [...this.newArr, ...(tabs.find((item) => item.id === tabActive) || {}).twoVideoCategory || []];
    }
  },
  created () {

  },
  async mounted () {

    const { type, sortType } = this.$route.query;
    this.tabActive = type * 1 || 0;
    this.params.sortType = sortType;
    this.newArr.unshift({ name: 'すべて', id: 0 });
    this.onResize();
    await this.getCategory();
    this.getMessage();
  },
  watch: {

    /* 监听窗口大小变化 */
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {
    async onLoad () {
      await this.getDetail();
    },

    /* 初始化数据 */
    init () {
      this.detail = {
        finished: false,
        loading: true,
        pageNo: 1,
        pageSize: 10,
        totalCount: '',
        list: []
      };
      this.getDetail();
    },

    /* 请求详情数据 */
    getDetail () {
      const { params: { categoryId, sortType, type, twoCategoryId } } = this;
      this.$http.get('video/searchVideo', {
        params: {
          categoryId: categoryId,
          sortType: sortType,
          type: type,
          twoCategoryId: twoCategoryId,
          pageNo: this.detail.pageNo,
          pageSize: this.detail.pageSize
        }
      }).then((res) => {
        const { detail } = this;
        const { isHasNext, nextPage, result, totalCount } = res.data || {};
        detail.list.push(...result);
        detail.finished = !isHasNext;
        detail.loading = false;
        detail.pageNo = nextPage;
        detail.totalCount = totalCount;
      });
    },

    /* 获取消息信息 */
    getMessage () {
      const { $http } = this;
      $http.get('homePage/isExistUnread', { customError: true }).then((res) => {
        this.newMessage = res.data;
      });
    },


    /* 请求分类的数据 */
    async getCategory () {
      let { tabActive, params, detail, $http } = this;
      await $http.get('video/categoryList').then((res) => {
        let list = res.data.list || [];
        list.unshift({ name: 'すべて', id: 0 });
        this.tabs = list;
        if (!tabActive && list.length) {
          tabActive = list[0].id || 0;
        }
        params.categoryId = tabActive || this.tabs[0].id;
        // 此时当页面一加载时就调用一次详情的接口数据
        detail.loading = false;
      });
    },

    /* 切换顶部导航事件 */
    tabClick (name, title) {
      this.params = {
        categoryId: '', // 视频类目编号
        twoCategoryId: 0, // 一级分类
        sortType: '', // 排序类型(1.最新上传,2.最多播放)
        type: '' // 视频类型(1.免费,2.VIP,3.4K)
      };
      this.params.categoryId = name;
      this.init();
    },

    /* 分类选择 */
    onClassify (key, val) {
      // 一级分类不能反选
      key === 'twoCategoryId' ? this.params[key] = val : this.params[key] = this.params[key] === val ? '' : val;
      this.params.key = this.params[key];
      this.init();
    },

    /* 添加或移除稍后观看视频 */
    later () {
      this.getDetail();
    },

    /* 分享视频 */
    share () {
      this.getDetail();
    },

    /* 详情分类分享 */
    detailShowFun (item) {
      const { $utils, $router } = this;
      if ($utils.getToken()) {
        this.showFun = !this.showFun;
        const { id, whetherLater, putaway, type, whetherBuy, name } = item;
        this.actions = { id, whetherLater, putaway, type, whetherBuy, name };
      } else {
        $utils.toast({
          message: 'ログインへ',
          onClose: () => {
            $router.push({
              name: 'Login',
              params: {
                type: 1
              }
            });
          }
        });
      }


    },

    /* 修改稍后播放与分享弹层的显影状态 */
    upDate (flag) {
      this.showFun = flag;
    },


    /* 页面响应式处理 */
    onResize () {
      const { htmlWidth, section1 } = this;
      section1.span = 24;
      if (htmlWidth > 992) {
        section1.span = 8;
      }
    }
  }
};
</script>
<style scoped lang="less">
  #categoryPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    /deep/
    .van-tabs {
      margin-bottom: 28px;

      .van-tabs__wrap {
        height: auto;

        .van-tabs__nav--line {
          padding: 0 0 20px;

          .van-tab {
            font-size: 30px;
            line-height: 40px;
          }

          .van-tabs__line {
            bottom: 4px;
            width: 40px;
            height: 8px;
            border-radius: 4px;
          }
        }
      }
    }

    .container {
      position: relative;
      height: 100%;
      overflow-y: auto;

      .section {
        .section-title {
          position: relative;
          z-index: 10;
          margin-bottom: 48px;

          .list {
            padding-right: 28px;
            padding-left: 28px;

            &:not(:last-child) {
              margin-bottom: 32px;
            }

            .list-item {
              width: auto;
              cursor: pointer;

              .item {
                min-width: 94px;
                height: 48px;
                padding: 8px 24px;
                font-size: 24px;
                line-height: 32px;
                color: rgba(255, 255, 255, 0.6);
                text-align: center;
                background: rgba(255, 255, 255, 0.04);
                border-radius: 40px;
                transition: 0.2s all;

                &.active {
                  font-weight: bold;
                  color: #2d2e2e;
                  background: linear-gradient(180deg, #36abf5 0%, #7acbff 100%);

                  &.bg-1 {
                    background: linear-gradient(230deg, #ffdc4b 0%, #ffa143 100%);
                  }
                }
              }

              &:not(:last-child) {
                margin-right: 16px;
              }
            }
          }
        }

        .section-content {
          padding-right: 14px;
          padding-left: 14px;

          .list {
            /deep/
            .list-item {
              width: 100%;
              padding-right: 14px;
              padding-left: 14px;
              margin-bottom: 42px;

              .thumb {
                height: 348px;
              }

              .content {
                .name {
                  .text-overflow();
                }
              }
            }
          }
        }
      }
    }
  }
</style>